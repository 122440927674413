<template>
  <div class="media-player" ref="mediaPlayer">
    <!--  Header  -->
    <div class="media-player__header" :class="headerResponsiveClass">
      <div class="entertainment">
        <div>
          Entertain me
        </div>
        <div class="icn-entertainment">
          <img src="/icons/icn_entretenme.svg" alt="" />
        </div>
      </div>
      <!--      <div v-if="false" class="media-player__header__switch">
        <div class="media-player__header__switch__component">
          <div class="text" @click="isPlaySeekerActive = false">
            Summary
          </div>
          <div class="charts__switch__b-switch">
            <BSwitch v-model="isPlaySeekerActive" size="is-medium" />
          </div>
          <div class="text" @click="isPlaySeekerActive = true" style="white-space: nowrap">
            Entertain me
          </div>
        </div>
      </div>
      <div v-if="false" class="media-player__header__separator" />
      <div class="media-player__header__buttons">
        <template v-if="isPlaySeekerActive">
          <div class="media-player__header__buttons__entertainment">
            <div>
              Entertain me
            </div>
            <div class="icn-entertainment">
              <img src="/icons/icn_entretenme.svg" alt="" />
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="media-player__header__buttons__button"
            :class="{ 'media-player__header__buttons__button&#45;&#45;active': isGoalsActive }"
            @click="onPlaysTypeClicked(playsTypeOptions[0])"
          >
            <div>
              Goals
            </div>
            <div class="icn-goal">
              <img :src="`/icons/icn-goals${isGoalsActive ? '-active' : ''}.svg`" alt="" />
            </div>
          </div>
          <div
            class="media-player__header__buttons__button"
            :class="{ 'media-player__header__buttons__button&#45;&#45;active': !isGoalsActive }"
            @click="onPlaysTypeClicked(playsTypeOptions[1])"
          >
            <div>
              Relevant Plays
            </div>
            <div class="icn-relevant-plays">
              <img :src="`/icons/icn-relevant-plays${!isGoalsActive ? '-active' : ''}.svg`" alt="" />
            </div>
          </div>
        </template>
      </div>-->
    </div>
    <VideoPlayer
      v-show="!showImage"
      ref="videoPlayer"
      :player-queue="playerQueue"
      is-v2
      :container-width="mediaPlayerWidth"
      :game-id-selected="gameIdSelected"
      :is-play-seeker-active="isPlaySeekerActive"
      :concat-plays="concatPlays"
      @play-changed="onPlayChanged"
      @play-iteration="onPlayIteration"
      @max-inactivity-reached="onMaxInactivityReached"
      @playlist-ended="onPlaylistEnded"
      @change-type="onTypeChanged"
      @change-retro="onRetroChanged"
      @click-retro="onRetroClicked"
      @index-change="onIndexChanged"
    />
    <div class="media-player__image" v-show="showImage">
      <div class="media-player__image__text"><span>Next:</span> {{ seasonName }}</div>
    </div>
    <div class="media-player__play-info" :style="playInfoStyle">
      <div class="media-player__play-info__season">
        <p>
          Tournament <span style="font-weight: bold">{{ playInfo.season }}</span>
        </p>
      </div>
      <div v-if="isPlaySeekerActive" class="media-player__play-info__entertainment">
        <div v-if="playInfo.playTeam" class="team-logo">
          <img :src="`${logosUrl}${playInfo.playTeam}.png`" alt="" />
        </div>
        <div class="player">
          {{ playInfo.mainCategory ? playInfo.mainCategory.name : '' }} - {{ playInfo.playerShortName || '' }}
        </div>
        <div class="vs">VS</div>
        <div v-if="playInfo.rivalTeam" class="team-logo">
          <img :src="`${logosUrl}${playInfo.rivalTeam}.png`" alt="" />
        </div>
        <div>{{ playInfo.matchDayAbbreviation || '' }}</div>
      </div>
      <PlayInfo
        v-else
        :games-by-round="gamesByRound"
        :teams="teams"
        :play-info="playInfo"
        :info-type="selectedType"
        :container-width="mediaPlayerWidth"
        :is-retro="isRetroMode"
        :is-retro-mode-requested="isRetroRequested"
        :is-play-seeker-active="isPlaySeekerActive"
        @player-select="onPlayerSelected"
        @team-select="onTeamSelected"
        @game-id-select="onGameIdSelected"
      />
    </div>
    <div v-if="false" class="media-player__mode">
      <div class="media-player__mode__options">
        <div class="media-player__mode__options__background" :class="{ retro: isRetroMode }" />
        <div
          v-for="mode in playerModes"
          :key="mode.id"
          :class="{ active: playerModeSelected === mode.id }"
          @click="onPlayerModeClicked(mode.id)"
        >
          {{ mode.name }}
        </div>
      </div>
    </div>
    <div v-if="false" class="media-player__categories">
      <div
        class="media-player__categories__goals"
        :class="{ 'button--active': playsType === playsTypeOptions[0] }"
        @click="onPlaysTypeClicked(playsTypeOptions[0])"
      >
        <div>
          Goals
        </div>
      </div>
      <div
        class="media-player__categories__best-plays"
        :class="{ 'button--active': playsType === playsTypeOptions[1] }"
        @click="onPlaysTypeClicked(playsTypeOptions[1])"
      >
        <div>
          Relevant Plays
        </div>
      </div>
    </div>
    <div v-if="false" class="mediaplayer-player"></div>
    <FilterMenu
      v-if="false"
      @openmodal="openmodal"
      :current-plays-category="currentPlaysCategory"
      :play-info="playInfo"
      :are-goals-selected="playsType === playsTypeOptions[0]"
      :is-retro-mode="isRetroMode"
    />
    <FilterModal
      :show="showModal"
      :is-retro-mode="isRetroMode"
      @close="closeModal"
      @player-selected="onPlayerSelected"
      @team-selected="onTeamSelected"
    />
    <Confirm
      question="We have detected a long period of inactivity. You still there?"
      :showModalConfirm="showModalConfirm"
      @onConfirm="confirmModalConfirm"
      @onCancel="closeModalConfirm"
      :textConfirm="'Continue watching'"
      :textCancel="'Exit'"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { regularLeague } from '@/utils/play-off-abbreviations';
const playContentIndex = (function getPlayContentIndex() {
  const fields = [
    'categoryId',
    'playerId',
    'teamId',
    'gameId',
    'gameDate',
    'gameHour',
    'matchDayId',
    'matchLapse',
    'momentOfPlay',
    'startTime',
    'endTime',
    'syncStartFirstTime',
    'syncEndFirstTime',
    'syncStartSecondTime',
    'syncEndSecondTime',
    'syncStartFirstExtraTime',
    'syncEndFirstExtraTime',
    'syncStartSecondExtraTime',
    'syncEndSecondExtraTime',
    'syncStartPenals',
    'syncEndPenals',
    'jersey',
  ];
  return fields.reduce((accumulator, field, index) => {
    accumulator[index] = field;
    return accumulator;
  }, {});
})();
const playSeekerContentIndexes = (function getPlayIndexes() {
  const playProps = [
    'playId', // number
    'league', // string
    'season', // number
    'gameId', // number
    'playerName', // string
    'refereeName', // string
    'stadiumName', // string
    'finalScore', // string
    'scoreAtMomentOfPlay', // string
    'gameDate', // string
    'homeTeamName', // string
    'homeTeamLogo', // string
    'awayTeamName', // string
    'awayTeamLogo', // string
    'categoryId', // number
    'categoryName', // string
    'startTime', // number
    'endTime', // number
    'minuteOfPlay', // number
    'momentOfPlay', // number
    'videoUrl', // string
    // 'playTeam',
    // 'rivalTeam',
    // 'matchDayAbbreviation',
  ];
  return playProps.reduce((accumulator, propName, index) => {
    accumulator[propName] = index;
    return accumulator;
  }, {});
})();
let playsPerGame = null;
const playsTypeOptions = ['goals', 'bestPlays'];
const allCategoriesNamesAndWeighing = {
  1: { name: 'Goal opportunity', nameEs: 'Oportunidad de Gol', weighing: 3 },
  2: { name: 'Goal', nameEs: 'Gol', weighing: 1 },
  3: { name: 'Own goal', nameEs: 'Autogol', weighing: 2 },
  4: { name: 'Yellow card', nameEs: 'Tarjeta Amarilla', weighing: 5 },
  5: { name: 'Red card', nameEs: 'Tarjeta Roja', weighing: 4 },
  6: { name: 'Penalty received', nameEs: 'Penal Recibido', weighing: 6 },
  7: { name: 'Save', nameEs: 'Atajada', weighing: 8 },
  8: { name: 'Assist', nameEs: 'Asistencia', weighing: 9 },
  9: { name: 'Participation in goal', nameEs: 'Participación en Gol', weighing: 14 },
  10: { name: 'Outstanding', nameEs: 'Sobresaliente', weighing: 20 },
  11: { name: 'Successful center', nameEs: 'Centro Rematado', weighing: 11 },
  12: { name: 'Successful corner', nameEs: 'Corner Rematado', weighing: 12 },
  13: { name: 'Free kick met by teammate', nameEs: 'Tiro Libre Rematado', weighing: 13 },
  14: { name: 'Successful 1-on-1 attacking', nameEs: '1vs1 Exitoso Ofensivo', weighing: 16 },
  15: { name: 'Unsuccessful 1-on-1 def', nameEs: '1vs1 Exitoso Defensivo', weighing: 18 },
  16: { name: 'Through pass', nameEs: 'Pase Filtrado', weighing: 15 },
  17: { name: 'Balls won in own 18-y box', nameEs: 'Balón ganado en Área Propia', weighing: 19 },
  18: { name: 'Ball recovered', nameEs: 'Balón Recuperado', weighing: 17 },
  19: { name: 'Goal received', nameEs: 'Gol Recibido', weighing: 7 },
  20: { name: 'Originating play for goal', nameEs: 'Origen de Gol', weighing: 10 },
};
const goalCategories = {
  ['12-55']: allCategoriesNamesAndWeighing['2'],
};
const bestPlaysCategories = {
  games: {
    ['607-608']: allCategoriesNamesAndWeighing['1'],
    ['12-55']: allCategoriesNamesAndWeighing['2'],
    ['54-35']: allCategoriesNamesAndWeighing['3'],
    ['14-52']: allCategoriesNamesAndWeighing['4'],
    ['15-53']: allCategoriesNamesAndWeighing['5'],
    ['466-477']: allCategoriesNamesAndWeighing['6'],
  },
  team: {
    ['607-608']: allCategoriesNamesAndWeighing['1'],
    ['12-55']: allCategoriesNamesAndWeighing['2'],
    ['54-35']: allCategoriesNamesAndWeighing['3'],
    ['14-52']: allCategoriesNamesAndWeighing['4'],
    ['15-53']: allCategoriesNamesAndWeighing['5'],
    ['466-477']: allCategoriesNamesAndWeighing['6'],
    ['40-257']: allCategoriesNamesAndWeighing['19'],
    ['39']: allCategoriesNamesAndWeighing['7'],
  },
  player: {
    regular: {
      ['12']: allCategoriesNamesAndWeighing['2'],
      ['35']: allCategoriesNamesAndWeighing['3'],
      ['10']: allCategoriesNamesAndWeighing['8'],
      ['27']: allCategoriesNamesAndWeighing['9'],
      ['607']: allCategoriesNamesAndWeighing['1'],
      ['61']: allCategoriesNamesAndWeighing['20'],
      ['519']: allCategoriesNamesAndWeighing['10'],
      ['98-101-109-112']: allCategoriesNamesAndWeighing['11'],
      ['121-122-127-128-135-136-141-142']: allCategoriesNamesAndWeighing['12'],
      ['161-170-179']: allCategoriesNamesAndWeighing['13'],
      ['702']: allCategoriesNamesAndWeighing['14'],
      ['730']: allCategoriesNamesAndWeighing['15'],
      ['351']: allCategoriesNamesAndWeighing['6'],
      ['217']: allCategoriesNamesAndWeighing['16'],
      ['211-232-235-239-242-282-284-289-291-296']: allCategoriesNamesAndWeighing['17'],
      ['485-486-487-488']: allCategoriesNamesAndWeighing['18'],
      ['14']: allCategoriesNamesAndWeighing['4'],
      ['15']: allCategoriesNamesAndWeighing['5'],
      ['257']: allCategoriesNamesAndWeighing['19'],
    },
    goalkeeper: {
      ['55']: allCategoriesNamesAndWeighing['2'],
      ['324']: allCategoriesNamesAndWeighing['8'],
      ['323']: allCategoriesNamesAndWeighing['9'],
      ['608']: allCategoriesNamesAndWeighing['1'],
      ['39']: allCategoriesNamesAndWeighing['7'],
      ['774']: allCategoriesNamesAndWeighing['14'],
      ['802']: allCategoriesNamesAndWeighing['15'],
      ['52']: allCategoriesNamesAndWeighing['4'],
      ['53']: allCategoriesNamesAndWeighing['5'],
      ['40']: allCategoriesNamesAndWeighing['19'],
      ['54']: allCategoriesNamesAndWeighing['3'],
    },
  },
};
const playSeekerClientId = 1;

export default {
  components: {
    // BSwitch: async () => {
    //   const { BSwitch } = await import('buefy/dist/esm/switch');
    //   return BSwitch;
    // },
    FilterMenu: () => import('@/components/Sections/MediaPlayer/FilterMenu'),
    FilterModal: () => import('@/components/Sections/MediaPlayer/FilterModal'),
    VideoPlayer: () => import('@/components/Sections/MediaPlayer/VideoPlayer'),
    Confirm: () => import('@/components/Modals/ConfirmMediaPlayer'),
    PlayInfo: () => import('@/components/Sections/MediaPlayer-v2/PlayInfo'),
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logosUrl: 'https://az755631.vo.msecnd.net/teams-80/',
      showModalConfirm: false,
      showModal: false,
      seasonId: 0,
      plays: [],
      playerQueue: [],
      playsType: playsTypeOptions[0],
      playsTypeOptions: playsTypeOptions,
      playInfo: {},
      teamsMap: null,
      gamesMap: null,
      roundsMap: null,
      mediaPlayerMode: 'games', // values can be 'games', 'team' or 'player'
      playerPosition: 0,
      playlistTournament: null,
      showImage: false,
      seasonName: '',
      playlistSeasonIndex: 0,
      playlistSeasonIndexAux: 0,
      playerModes: {
        present: {
          id: 1,
          name: 'Actualidad',
        },
        retro: {
          id: 2,
          name: 'Retro',
        },
      },
      playerModeSelected: 1,
      isRetroModeApplied: false,
      currentTournament: { seasons: [] },
      mediaPlayerResizeObserver: null,
      mediaPlayerWidth: 1024,
      selectedType: 1,
      isRetroMode: false,
      isRetroRequested: false,
      roundsAndGamesWithPlaysMap: null,
      gameIdSelected: 0,
      isPlaySeekerActive: false,
      playSeekerPlays: [],
      geoOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      coords: null,
      playSeekerUserId: '',
      playSeekerCurrentSessionId: '',
      concatPlays: false,
      currentPlayIndex: 0,
      firstInteractionToken: '',
      firstInteractionUrl: 'http://a73767204107d404b9ba7934936ca080-113677222.us-west-2.elb.amazonaws.com:8051',
      userBehaviorUrl: 'http://a2f03e341a0aa4915891b1114bc139b7-377506899.us-west-2.elb.amazonaws.com:8080',
    };
  },
  computed: {
    ...mapGetters('mediaGeneral', [
      'getSeasonIdOnly',
      'getTeamId',
      'getPlayerId',
      'seasonPlayers',
      'selectedTournament',
      'isPlayerGoalkeeper',
      'playersByTeam',
    ]),
    ...mapState('mediaGeneral', ['tournaments', 'seasonID', 'retroSeason', 'retroTournament']),
    currentCategories() {
      return this.isPlaySeekerActive
        ? [...this.getBestCategoriesIds(), ...this.getGoalCategoriesIds()]
        : this.playsType === playsTypeOptions[1]
        ? this.getBestCategoriesIds()
        : this.getGoalCategoriesIds();
    },
    currentPlaysCategory() {
      if (this.playsType) {
        return this.playsType === playsTypeOptions[0] ? 'goles' : 'jugadas relevantes';
      }
      return '';
    },
    allCategories() {
      const allCategories = new Map();
      Object.entries(goalCategories).forEach(([key, value]) => {
        key.split('-').forEach(categoryIdString => {
          allCategories.set(Number(categoryIdString), value);
        });
      });
      this.setBestCategoriesNames(allCategories);
      return allCategories;
    },
    allCategoriesPlaySeeker() {
      const allCategoriesPlaySeeker = new Map();
      this.addCategoriesToMap(allCategoriesPlaySeeker, goalCategories);
      this.addCategoriesToMap(allCategoriesPlaySeeker, bestPlaysCategories.games);
      this.addCategoriesToMap(allCategoriesPlaySeeker, bestPlaysCategories.team);
      this.addCategoriesToMap(allCategoriesPlaySeeker, bestPlaysCategories.player.regular);
      this.addCategoriesToMap(allCategoriesPlaySeeker, bestPlaysCategories.player.goalkeeper);
      return allCategoriesPlaySeeker;
    },
    queryCategories() {
      return this.currentCategories.reduce((accumulator, categoryId, index) => {
        // eslint-disable-next-line no-param-reassign
        accumulator += `${index > 0 ? '&' : ''}categories=${categoryId}`;
        return accumulator;
      }, '');
    },
    gamesByRound() {
      const gamesByRound = {};
      if (this.roundsAndGamesWithPlaysMap) {
        for (const [round, games] of this.roundsAndGamesWithPlaysMap) {
          const [name, abbreviation] = round.split(',');
          const gamesArr = Array.from(games.values());
          gamesArr.sort((a, b) => b.dateTimeMillis - a.dateTimeMillis);
          gamesByRound[abbreviation] = {
            name,
            games: gamesArr,
          };
        }
      }
      return gamesByRound;
    },
    teams() {
      if (this.teamsMap) {
        return Array.from(this.teamsMap.values());
      }
      return [];
    },
    isGoalsActive() {
      return this.playsType === playsTypeOptions[0];
    },
    headerResponsiveClass() {
      const responsiveClass = [];
      // if (this.mediaPlayerWidth < 401) {
      //   responsiveClass.push('header--width-400');
      // } else if (this.mediaPlayerWidth < 541) {
      //   responsiveClass.push('header--width-540');
      // }
      return responsiveClass;
    },
    playInfoStyle() {
      if (this.mediaPlayerWidth < 871) {
        return { padding: '0 0.5em' };
      }
      return {};
    },
  },
  watch: {
    playerModeSelected: {
      immediate: true,
      handler(newValue) {
        this.isRetroMode = newValue === this.playerModes.retro.id;
      },
    },
    selectedTournament: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.playlistTournament = newValue;
          this.playlistSeasonIndex = 0;
        }
      },
    },
    seasonID: {
      deep: true,
      handler(newValue) {
        if (newValue && !this.isRetroMode) {
          this.playlistSeasonIndexAux = newValue.index;
        }
      },
    },
    retroSeason: {
      deep: true,
      handler(newValue) {
        if (newValue && this.isRetroMode) {
          this.playlistSeasonIndexAux = newValue.index;
        }
      },
    },
    retroTournament: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.tournament_id) {
          this.fetchInfo(false, newValue);
        }
      },
    },
    async tournaments(newValue) {
      if (newValue && newValue.length) {
        await this.fillSeasons(newValue);
      }
    },
    playsType() {
      this.fetchInfo(true, this.playlistTournament);
    },
    isRetroMode(newValue) {
      this.playlistSeasonIndex = 0;
      this.setPlayer(null);
      this.setTeam(null);
      if (newValue) {
        if (this.retroTournament && this.retroTournament.tournament_id) {
          this.isRetroRequested = true;
          this.fetchInfo(false, this.retroTournament);
        }
      } else if (this.isRetroRequested) {
        this.isRetroRequested = false;
        this.fetchInfo(false, this.currentTournament);
      }
    },
    isPlaySeekerActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fillPlaySeekerPlays();
        } else {
          this.fetchTournaments();
        }
      },
    },
  },
  methods: {
    ...mapActions('mediaGeneral', ['fetchTournaments', 'fetchSeasonsGamesAndTeams', 'getPlayersBySeasons']),
    ...mapMutations('mediaGeneral', ['setTeam', 'setPlayer']),
    addCategoriesToMap(map, categoriesObject) {
      Object.entries(categoriesObject).forEach(([key, value]) => {
        const idsArr = key.split('-');
        idsArr.forEach(categoryId => {
          map.set(Number(categoryId), value);
        });
      });
    },
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    confirmModalConfirm() {
      this.showModalConfirm = false;
      this.$refs.videoPlayer.playCurrentVideoPlayer();
    },
    openmodal() {
      this.$refs.videoPlayer.setAutoPlaysCount(0);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async fillSeasons(tournaments) {
      try {
        if (tournaments) {
          const mxLeague = tournaments.find(tournament => tournament._id === 1);
          if (mxLeague) {
            this.currentTournament = mxLeague;
            await this.fetchInfo(false, this.currentTournament);
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getGoalCategoriesIds() {
      return Object.keys(goalCategories).reduce((accumulator, categoryId) => {
        categoryId.split('-').forEach(categoryIdString => {
          accumulator.push(Number(categoryIdString));
        });
        return accumulator;
      }, []);
    },
    getBestCategoriesIds() {
      let keys = null;
      if (this.mediaPlayerMode === 'player') {
        if (this.isPlayerGoalkeeper) {
          keys = Object.keys(bestPlaysCategories.player.goalkeeper);
        } else {
          keys = Object.keys(bestPlaysCategories.player.regular);
        }
      } else {
        keys = Object.keys(bestPlaysCategories[this.mediaPlayerMode]);
      }
      return keys.reduce((accumulator, key) => {
        key.split('-').forEach(categoryIdString => {
          accumulator.push(Number(categoryIdString));
        });
        return accumulator;
      }, []);
    },
    setBestCategoriesNames(namesMap) {
      let entries = null;
      if (this.mediaPlayerMode === 'player') {
        if (this.isPlayerGoalkeeper) {
          entries = Object.entries(bestPlaysCategories.player.goalkeeper);
        } else {
          entries = Object.entries(bestPlaysCategories.player.regular);
        }
      } else {
        entries = Object.entries(bestPlaysCategories[this.mediaPlayerMode]);
      }
      entries.forEach(([key, value]) => {
        key.split('-').forEach(categoryIdString => {
          namesMap.set(Number(categoryIdString), value);
        });
      });
    },
    async fetchPlays() {
      try {
        let queryParams = '';
        let et = '';
        if (this.getTeamId || this.getPlayerId) {
          et = '&';
          if (this.getPlayerId) {
            queryParams += `player=${this.getPlayerId}`;
          } else {
            queryParams += `team=${this.getTeamId}`;
          }
        }
        const response = await axios.get(
          `https://gpela9u46i.execute-api.us-west-2.amazonaws.com/prod/videos/plays/seasons/${this.seasonId}?${this.queryCategories}${et}${queryParams}`,
        );
        if (response.data && response.data.plays) {
          return response.data.plays;
        }
        return [];
      } catch (e) {
        throw new Error(e);
      }
    },
    onPlaysTypeClicked(playsType) {
      this.playsType = playsType;
    },
    getPlayTime(gamePlay, timeProp) {
      let syncTime = '';
      let minutesToSubtract = 0;
      switch (gamePlay.matchLapse) {
        case 1:
          syncTime = gamePlay.syncStartFirstTime;
          break;
        case 2:
          syncTime = gamePlay.syncStartSecondTime;
          minutesToSubtract = 45;
          break;
        case 3:
          syncTime = gamePlay.syncStartFirstExtraTime;
          minutesToSubtract = 90;
          break;
        case 4:
          syncTime = gamePlay.syncStartSecondExtraTime;
          minutesToSubtract = 105;
          break;
        case 5:
          syncTime = gamePlay.syncStartPenals;
          minutesToSubtract = 120;
          break;
      }
      if (!syncTime || syncTime === '0') {
        syncTime = '00:00';
      }
      return this.getTimeToSeconds(syncTime) + (this.getTimeToSeconds(gamePlay[timeProp]) - minutesToSubtract * 60);
    },
    getTimeToSeconds(timeString) {
      const dotIndex = timeString.indexOf('.');
      const stringAux = dotIndex > -1 ? timeString.substring(0, timeString.indexOf('.')) : timeString;
      const [seconds, minutes, hours] = stringAux.split(':').reverse();

      return (hours ? Number(hours) * 60 * 60 : 0) + Number(minutes) * 60 + Number(seconds);
    },
    async fillPlaySeekerPlays(booleanRefreshQueueData) {
      try {
        if (booleanRefreshQueueData) {
          this.playerQueue = [];
          this.$refs.videoPlayer.resetCurrentPlayIndex();
        }
        if (this.isPlaySeekerActive) {
          const userId = this.userId.trim() || null;
          const response = await fetch(
            `${this.firstInteractionUrl}/firstInteraction/${playSeekerClientId}/${userId}/playlist`,
            {
              method: 'GET',
              headers: {
                Authorization:
                  // eslint-disable-next-line max-len
                  `Bearer ${this.firstInteractionToken}`,
              },
              referrerPolicy: 'no-referrer',
            },
          );
          const data = await response.json();
          if (data) {
            this.playSeekerUserId = data.user_id;
            this.playSeekerCurrentSessionId = this.getPlaySeekerSessionId(this.playSeekerUserId);
            this.$emit('change-user-id', this.playSeekerUserId);
            window.localStorage.setItem('playSeekerUserId', this.playSeekerUserId);
            this.formatPlaySeekerPlays(data);
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    formatPlaySeekerPlays(listData) {
      let formattedPlays = [];
      if (listData && listData.playlist && listData.playlist.length) {
        formattedPlays = listData.playlist.reduce((accumulator, play, index) => {
          const playObject = Object.entries(playSeekerContentIndexes).reduce((accumulator, [key, value]) => {
            accumulator[key] = play[value];
            return accumulator;
          }, {});
          const playInfo = {
            ...playObject,
            isPlaySeeker: true,
            startSeconds: play[playSeekerContentIndexes.startTime],
            endSeconds: play[playSeekerContentIndexes.endTime],
            totalSeconds: Math.floor(play[playSeekerContentIndexes.endTime] - play[playSeekerContentIndexes.startTime]),
            payloadData: {
              play_id: play[playSeekerContentIndexes.playId],
              total_plays: listData.total_plays,
              play_number: index + 1,
              group_playlist_id: listData.group_playlist_id,
              playlist_id: listData.playlist_id,
              group_id: listData.group_id,
              moment_of_play: play[playSeekerContentIndexes.momentOfPlay],
              play_type: play[playSeekerContentIndexes.categoryId],
              viewer_second_start: Math.floor(play[playSeekerContentIndexes.startTime]),
              intended_second_start: Math.floor(play[playSeekerContentIndexes.startTime]),
              intended_second_end: Math.floor(play[playSeekerContentIndexes.endTime]),
              latitude_of_user: null,
              longitude_of_user: null,
            },
          };
          const [lastName, name] = playInfo.playerName.split(',');
          const lastNameArr = lastName.split(' ');
          let lastNameShort = '';
          for (const word of lastNameArr) {
            lastNameShort += ` ${word}`;
            if (word.length > 3) {
              break;
            }
          }
          playInfo.playerShortName = name && lastName ? `${name.trim().charAt(0)}.${lastNameShort}` : '';
          playInfo.mainCategory = this.allCategoriesPlaySeeker.get(playInfo.categoryId);
          accumulator.push(playInfo);
          return accumulator;
        }, []);
      }
      let arrayPlayerQueue = [];
      if (this.concatPlays) {
        arrayPlayerQueue = [...this.playerQueue.splice(0, this.currentPlayIndex + 1), ...formattedPlays];
      } else {
        arrayPlayerQueue = formattedPlays;
      }
      this.playerQueue = arrayPlayerQueue;
    },
    getPlaySeekerSessionId(userId) {
      if (userId) {
        return `S_${userId}${Date.now()}`;
      }
      return null;
    },
    formatPlays(tournamentName, seasonName) {
      this.concatPlays = false;
      this.roundsAndGamesWithPlaysMap = new Map();
      playsPerGame = new Map();
      this.plays.forEach(play => {
        const playObject = play.reduce((accumulator, value, index) => {
          accumulator[playContentIndex[index]] = value;
          return accumulator;
        }, {});
        const gameAux = this.gamesMap.get(playObject.gameId) || { homeTeam: { name: '' }, awayTeam: { name: '' } };
        const roundKey = `${gameAux.roundName},${gameAux.roundAbbreviation}`;
        const roundAux = this.roundsAndGamesWithPlaysMap.get(roundKey);
        if (roundAux) {
          roundAux.set(gameAux.id, gameAux);
        } else {
          this.roundsAndGamesWithPlaysMap.set(roundKey, new Map().set(gameAux.id, gameAux));
        }
        playObject.tournamentName = tournamentName;
        playObject.seasonName = seasonName;
        playObject.game = gameAux;
        playObject.player = this.seasonPlayers.get(playObject.playerId);
        playObject.playerTeamLogo = this.teamsMap.get(playObject.player.teamId).logo;
        playObject.rivalTeamLogo = this.teamsMap.get(
          playObject.player.teamId === playObject.game.homeTeam.id
            ? playObject.game.awayTeam.id
            : playObject.game.homeTeam.id,
        ).logo;
        playObject.startSeconds = this.getPlayTime(playObject, 'startTime');
        playObject.endSeconds = this.getPlayTime(playObject, 'endTime');
        const gameDateTime = new Date(playObject.gameDate);
        const [hours, minutes] = playObject.gameHour.split(':');
        gameDateTime.setHours(hours);
        gameDateTime.setMinutes(minutes);
        playObject.dateTimeMillis = gameDateTime.valueOf();
        if (!playsPerGame.has(playObject.gameId)) {
          playsPerGame.set(playObject.gameId, { dateTimeMillis: gameDateTime.valueOf(), plays: [playObject] });
        } else {
          const playsInGame = playsPerGame.get(playObject.gameId);
          playsInGame.plays.push(playObject);
        }
      });
      const playsPerGameArray = Array.from(playsPerGame.values());
      playsPerGameArray.sort((a, b) => {
        return b.dateTimeMillis - a.dateTimeMillis;
      });
      this.plays = playsPerGameArray.reduce((accumulator, playsInGame) => {
        playsInGame.plays.sort((a, b) => {
          return (
            Number(`${a.matchLapse}${a.startTime.replace(/:*\.*/g, '')}`) -
            Number(`${b.matchLapse}${b.startTime.replace(/:*\.*/g, '')}`)
          );
        });
        accumulator.push(...playsInGame.plays);
        return accumulator;
      }, []);
    },
    generatePlayerQueue(categoriesId) {
      const playsMap = new Map();
      this.playerQueue = this.plays.reduce((accumulator, play) => {
        // todo: add momentOfPlay and endTime
        const playKey = `${play.gameId}-${play.playerId}-${play.startTime}`;
        if (categoriesId.includes(play.categoryId) && play.startSeconds !== play.endSeconds) {
          const playAux = playsMap.get(playKey);
          if (!playAux) {
            const categoryAux = this.allCategories.get(play.categoryId);
            play.mainCategory = categoryAux;
            play.categoriesNames = new Set();
            play.categoriesNames.add(categoryAux.name);
            playsMap.set(playKey, play);
            accumulator.push(play);
          } else {
            const categoryAux = this.allCategories.get(play.categoryId);
            if (categoryAux.weighing < playAux.mainCategory.weighing) {
              playAux.mainCategory = categoryAux;
            }
            playAux.categoriesNames.add(categoryAux.name);
          }
        }
        return accumulator;
      }, []);
    },
    /**
     *
     * @param {number} playerTime
     * @param {number} userAction - values can be: 0 = next clicked, 1 = play ended, 2 = back clicked
     * @param {string} dateTimeIsoString
     */
    async sendUserBehavior(playerTime, userAction, dateTimeIsoString) {
      try {
        if (this.playInfo.isPlaySeeker) {
          const payload = {
            user_id: this.playSeekerUserId,
            current_session_id: this.playSeekerCurrentSessionId,
            last_play: {
              ...this.playInfo.payloadData,
            },
          };
          if (payload) {
            payload.last_play.action = userAction;
            payload.last_play.percentage_viewed = userAction === 1 ? 100 : this.getRandomPercentage();
            payload.last_play.viewer_second_end = playerTime || this.playInfo.endSeconds;
            payload.last_play.date_time_of_user_action = dateTimeIsoString;
            if (this.coords) {
              payload.last_play.latitude_of_user = this.coords.latitude;
              payload.last_play.longitude_of_user = this.coords.latitude;
            }
          }
          const response = await fetch(`${this.userBehaviorUrl}/user-behavior`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          if (response.status === 200) {
            const data = await response.json();
            if (data) {
              this.concatPlays = true;
              this.formatPlaySeekerPlays(data);
            }
          } else {
            this.concatPlays = false;
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getRandomPercentage() {
      return Math.floor(Math.random() * (80 - 20) + 20);
    },
    onPlaylistEnded() {
      /*this.playlistSeasonIndex++;
      if (this.playlistSeasonIndex > this.playlistTournament.seasons.length - 1) {
        this.playlistSeasonIndex = 0;
      }
      this.seasonName = this.playlistTournament.seasons[this.playlistSeasonIndex].name;
      this.showImage = true;
      setTimeout(() => {
        this.fetchInfo(false, this.playlistTournament);
      }, 4000);*/
    },
    onTypeChanged(type = 1) {
      this.selectedType = type;
      if (this.selectedType === 2) {
        this.mediaPlayerMode = 'player';
        this.setPlayer(this.playInfo.player);
        this.onPlayerSelected();
      } else if (this.selectedType === 3) {
        this.mediaPlayerMode = 'team';
        this.setPlayer(null);
        this.setTeam(this.playInfo.teamId);
        this.onTeamSelected();
      } else {
        this.mediaPlayerMode = 'games';
        this.setPlayer(null);
        this.setTeam(null);
        this.checkSeason();
      }
    },
    onRetroChanged(isRetro) {
      this.isRetroMode = isRetro;
    },
    onRetroClicked() {
      this.showModal = true;
    },
    onMaxInactivityReached() {
      this.showModalConfirm = true;
    },
    checkSeason() {
      this.playlistSeasonIndex = this.playlistSeasonIndexAux;
      if (!this.isRetroModeApplied) {
        const isSameSeason = this.getSeasonIdOnly === this.seasonId;
        this.isRetroRequested = false;
        this.fetchInfo(isSameSeason, this.playlistTournament);
      } else {
        // todo: check retro behavior when videos ends
        const isSameSeason = this.retroSeason.id === this.seasonId;
        this.isRetroRequested = true;
        this.fetchInfo(isSameSeason, this.retroTournament);
      }
    },
    onPlayerSelected() {
      this.isRetroModeApplied = this.isRetroMode;
      this.mediaPlayerMode = 'player';
      this.checkSeason();
    },
    onTeamSelected() {
      this.isRetroModeApplied = this.isRetroMode;
      this.mediaPlayerMode = 'team';
      this.checkSeason();
    },
    onGameIdSelected(gameId) {
      this.gameIdSelected = gameId;
    },
    onPlayIteration({ iterationTime, userAction, dateTimeIsoString }) {
      if (this.isPlaySeekerActive) {
        this.sendUserBehavior(iterationTime, userAction, dateTimeIsoString);
      }
    },
    onPlayChanged(play) {
      if (play.isPlaySeeker) {
        this.playInfo = play;
      } else {
        this.playInfo = {
          date: play.gameDate,
          playerId: play.player.id,
          playerName: play.player.name,
          playerShortName: play.player.shortName,
          playCategory: play.categoryName,
          teams: `${play.game.homeTeam.name} vs ${play.game.awayTeam.name}`,
          playerPhoto: play.player.photo,
          teamLogo: play.playerTeamLogo,
          rivalLogo: play.rivalTeamLogo,
          teamId: play.teamId,
          categoryId: play.categoryId,
          jersey: play.jersey,
          league: play.tournamentName,
          season: play.seasonName,
          mainCategoryName: play.mainCategory.name,
          categoriesNames: Array.from(play.categoriesNames.values()).reduce((accumulator, categoryName, index) => {
            // eslint-disable-next-line no-param-reassign
            accumulator += `${index === 0 ? '' : ', '}${categoryName}`;
            return accumulator;
          }, ''),
          gameId: play.game.id,
          roundAbbreviation: play.game.roundAbbreviation,
          player: play.player,
          asLocal: play.game.homeTeam.id === play.teamId,
        };
      }
      this.$emit('play-change', play);
    },
    onGeoSuccess($event) {
      this.coords = $event.coords;
    },
    onGeoError(error) {
      throw new Error(error);
    },
    onIndexChanged(newIndex) {
      this.currentPlayIndex = newIndex;
    },
    async fetchInfo(isSameSeason, tournament) {
      try {
        if (!tournament) {
          return;
        }
        let playlistSeasonIndex = this.playlistSeasonIndex;
        this.playlistTournament = tournament;
        const currentSeason = tournament.seasons[playlistSeasonIndex];
        this.seasonId = currentSeason.id;
        const promises = [this.fetchPlays()];
        if (!isSameSeason) {
          const seasons = [this.seasonId];
          if (tournament.seasons[playlistSeasonIndex].related_seasons) {
            seasons.push(...tournament.seasons[playlistSeasonIndex].related_seasons.map(season => season.id));
          }
          promises.push(this.fetchSeasonsGamesAndTeams({ seasons }));
          promises.push(this.getPlayersBySeasons({ seasonId: this.seasonId }));
        }
        const [plays, gamesAndTeams] = await Promise.all(promises);
        this.plays = plays;
        if (!isSameSeason) {
          this.formatGamesAndTeams(gamesAndTeams);
        }
        // this.formatPlays(tournament.tournament_name || tournament.tournament.name, currentSeason.name);
        // this.generatePlayerQueue(this.currentCategories);
        this.showImage = false;
      } catch (e) {
        throw new Error(e);
      }
    },
    getBestPlaysCategoriesIds() {
      return Object.keys(bestPlaysCategories[this.mediaPlayerMode]).reduce((accumulator, categoryId) => {
        categoryId.split('-').forEach(categoryIdString => {
          accumulator.push(Number(categoryIdString));
        });
        return accumulator;
      }, []);
    },
    formatGamesAndTeams(gamesAndTeams) {
      this.roundsMap = new Map();
      this.teamsMap = gamesAndTeams.seasonsAndTeams.reduce((accumulator, season) => {
        season.teams.forEach(team => {
          if (!accumulator.has(team.team_id)) {
            accumulator.set(team.team_id, {
              name: team.colloquial_name,
              acronym: team.acronym,
              logo: team.logo,
              id: team.team_id,
              players: this.playersByTeam.get(team.team_id),
            });
          }
        });
        return accumulator;
      }, new Map());
      this.gamesMap = gamesAndTeams.seasonsAndGames.reduce((accumulator, season) => {
        season.games.forEach(game => {
          game.roundAbbreviation =
            regularLeague[game.matchday_abbreviation] || game.matchday_abbreviation.replace('M', 'M');
          game.roundName =
            regularLeague[game.matchday_abbreviation] || game.matchday_abbreviation.replace('M', 'Matchday ');
          const gameAux = {
            id: game.game_id,
            date: game.date,
            dateTimeMillis: new Date(game.date_time_utc).valueOf(),
            homeTeam: this.teamsMap.get(game.home_team),
            awayTeam: this.teamsMap.get(game.visiting_team),
            roundAbbreviation: game.roundAbbreviation,
            roundName: game.roundName,
          };
          const round = this.roundsMap.get(game.roundAbbreviation);
          if (round) {
            round.push(gameAux);
          } else {
            this.roundsMap.set(game.roundAbbreviation, [gameAux]);
          }
          if (!accumulator.has(game.game_id)) {
            accumulator.set(game.game_id, gameAux);
          }
        });
        return accumulator;
      }, new Map());
    },
    onPlayerModeClicked(modeId) {
      this.playerModeSelected = modeId;
    },
    setUpResizeObserver() {
      if (window.ResizeObserver) {
        this.mediaPlayerResizeObserver = new window.ResizeObserver(() => {
          this.mediaPlayerWidth = this.$refs.mediaPlayer.clientWidth;
        });
        this.mediaPlayerResizeObserver.observe(this.$refs.mediaPlayer);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    async setUpTokens() {
      try {
        const response = await fetch(`${this.firstInteractionUrl}/token`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Basic bXN1c2VyQWRtaW46bXN1c2VycGFzc3dvcmRBZG1pbg==',
          },
          referrerPolicy: 'no-referrer',
        });
        const data = await response.json();
        this.firstInteractionToken = data.token;
        this.isPlaySeekerActive = true;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  async created() {
    try {
      navigator.geolocation.getCurrentPosition(this.onGeoSuccess, this.onGeoError, this.geoOptions);
      await this.fetchTournaments();
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    this.setUpTokens();
    setTimeout(() => {
      this.setUpResizeObserver();
    }, 1000);
  },
};
</script>
<style lang="scss">
html > body {
  padding-top: 0 !important;
}

.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
$blue: #132739;
$green: #cbee6b;

.entertainment {
  display: flex;
  width: 100%;
  height: 2.2em;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  border-radius: 8px;
  border: solid 1.3px #cbee6b;
  //max-width: 90%;
  text-align: center;
  color: $blue;
  background-color: $green;
  font-family: Avenir-Pro-Bold, sans-serif;
  margin: 0.5em 0;

  & > div:first-child {
    margin-top: 0.2em;
    margin-right: 1em;
  }

  & > .icn-entertainment {
    width: 35px;
    height: 35px;

    & > img {
      width: 35px;
      height: 35px;
    }
  }
}

.media-player {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;

  &__header {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr /*min-content 66%*/;
    background-color: $blue;
    border-bottom: solid 3px $green;
    padding: 0 1.5em;

    &__switch {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $green;

      &__component {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4em 0.8em;
        border-radius: 10px;
        border: 1px solid #6e8191;
        margin: 0.6em 0;

        & > .text {
          cursor: pointer;
        }

        & > div:first-child {
          margin-right: 1em;
        }
      }
    }

    &__separator {
      align-self: center;
      height: 1.5em;
      border-right: 1px dotted #506270;
      margin: 0 2em;
    }

    &__buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $green;
      background-color: $blue;
      width: 100%;

      &__entertainment {
        display: flex;
        width: 100%;
        height: 2.2em;
        justify-content: center;
        align-items: center;
        font-size: 1.25em;
        border-radius: 8px;
        border: solid 1.3px #cbee6b;
        max-width: 90%;
        text-align: center;
        color: $blue;
        background-color: $green;
        font-family: Avenir-Pro-Bold, sans-serif;

        & > div:first-child {
          margin-top: 0.2em;
          margin-right: 1em;
        }

        & > .icn-entertainment {
          width: 35px;
          height: 35px;

          & > img {
            width: 35px;
            height: 35px;
          }
        }
      }

      &__button {
        display: flex;
        width: 15em;
        height: 2.2em;
        justify-content: center;
        align-items: center;
        font-size: 1.25em;
        border-radius: 8px;
        border: solid 1.3px #cbee6b;
        max-width: 15em;
        text-align: center;
        margin: 0.5em 0.3em;
        font-family: Avenir-Pro-Medium, sans-serif;
        cursor: pointer;

        & > div:first-child {
          margin-right: 0.7em;
        }

        & > .icn-goal {
          height: 1.5em;
          width: 1.5em;
          & > img {
            height: 1.5em;
            width: 1.5em;
          }
        }

        & > .icn-relevant-plays {
          height: 1.2em;
          width: 1.2em;

          & > img {
            height: 1.2em;
            width: 1.2em;
          }
        }

        &--active {
          padding-top: 0.2em;
          color: $blue;
          background-color: $green;
          font-family: Avenir-Pro-Bold, sans-serif;
        }
      }
    }

    &.header--width-540 {
      .media-player__header {
        &__buttons {
          &__button {
            font-size: 0.9125em;
            height: 2.3em;

            & > .icn-goal {
              height: auto;
              width: 1.5em;
            }

            & > .icn-relevant-plays {
              height: auto;
              width: 1.5em;
            }

            &--active {
            }
          }
        }
      }
    }

    &.header--width-400 {
      .media-player__header {
        &__buttons {
          &__button {
            font-size: 0.8125em;
            height: 2.4615em;
            width: fit-content;
            padding: 0.3em 1em 0;
            white-space: nowrap;

            & > .icn-goal {
              height: auto;
              width: 1.5em;
            }

            & > .icn-relevant-plays {
              height: auto;
              width: 1.5em;
            }

            &--active {
            }
          }
        }
      }
    }
  }

  &__play-info {
    width: 100%;
    margin-top: 0.5em;

    &__season {
      font-size: 1.5em;
      border-top: dashed 1px #dfe0e2;
      border-bottom: dashed 1px #dfe0e2;
      margin-bottom: 0.6rem;
      padding-top: 0.2em;

      & span {
        font-family: Avenir-Pro-Bold, sans-serif;
      }
    }

    &__entertainment {
      font-size: 1.4375em;
      width: 100%;
      border-radius: 0.5em;
      border: solid 3px $green;
      padding: 0.5em;
      font-family: Roboto-Medium, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;

      & .player {
        margin-right: 1em;
      }

      & .vs {
        margin-left: 1em;
      }

      & .team-logo {
        height: 48px;
        width: 48px;
        margin: 0 0.8em;

        & img {
          height: 48px;
          width: 48px;
        }
      }
    }
  }

  &__mode {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2rem 0 1rem;

    &__options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 90%;
      border-radius: 15px;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.19);
      background-color: #f6f6f6;
      font-size: 1.6rem;
      font-family: Avenir-Medium, sans-serif;
      color: #3e3e3e;
      position: relative;

      &__background {
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        background-color: #3c3c3c;
        border-radius: 15px;
        z-index: 0;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.21);

        &.retro {
          left: 50%;
        }
      }

      & > div {
        padding: 1.3rem;
        border-radius: 15px;
        cursor: pointer;
        z-index: 1;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;

        &.active {
          color: white;
        }
      }

      @media screen and (max-width: 620px) {
        width: 100%;
        font-size: 0.8rem;

        & > div {
          padding: 1.1rem 0;
          border-radius: 10px;
        }
      }
    }
  }

  &__categories {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 420px) {
      justify-content: space-between;
    }

    & > div {
      width: 16.25rem;
      height: 4.6rem;
      margin: 1rem;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Avenir-Pro-Bold, sans-serif;
      font-size: 1.25rem;
      border: solid 2px #d7dce2;
      color: #636462;

      &:first-child {
        @media screen and (max-width: 620px) {
          margin-right: 0.5rem;
        }
      }

      &:last-child {
        @media screen and (max-width: 620px) {
          margin-left: 0.5rem;
        }
      }

      @media screen and (max-width: 620px) {
        font-size: 0.8rem;
        height: 3rem;
        margin: 1rem 0;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    aspect-ratio: 9/5;
    background-image: url('/images/cortinilla_video.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      color: white;
      font-family: Avenir-Pro-Medium, sans-serif;
      font-size: 2rem;

      & > span {
        font-family: Avenir-Pro-Bold, sans-serif;
      }

      @media screen and (max-width: 460px) {
        font-size: 1rem;
      }
    }
  }
}
.button--active {
  border: solid 2px #0c98fd !important;
  background-color: #0c98fd !important;
  color: white !important;
}
</style>
<style lang="scss">
$green-switch: #b8df4d;
.media-player {
  &__header {
    & .switch input[type='checkbox'] + .check {
      background: $green-switch;
    }

    & .switch input[type='checkbox']:checked + .check {
      background: $green-switch;
    }

    & .switch input[type='checkbox'] + .check:before {
      background: #132739 !important;
    }

    & span.check:before {
      background: #132739;
    }

    label.switch {
      margin-right: 1em;

      & span.control-label {
        display: none;
      }
    }
  }
}
</style>
